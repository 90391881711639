"use client";
import ReactTimeAgo from "react-time-ago";
import Link from "next/link";
import Image from "next/image";
import Card from "../primitives/card";

export default function RecentNews({
  articles,
  title,
  link,
  columns,
}: {
  articles: article[];
  title?: string;
  link?: string;
  columns?: 1 | 2 | 3 | 4;
}) {
  return (
    <div>
      <Card size="xl" className="mb-8">
        <div className="flex items-center justify-between mt-2 mb-6">
          <div className="uppercase text-headline-bold">
            {title ? title : "Recent News"}
          </div>
          <Link
            prefetch={false}
            href={`${link ? link : "/news"}`}
            className="uppercase text-label-bold text-secondary"
          >
            View All
          </Link>
        </div>

        <div
          className={`grid gap-6 ${
            columns === 1
              ? "desktop-grid:grid-cols-1"
              : columns === 3
              ? "desktop-grid:grid-cols-3"
              : columns === 4
              ? "desktop-grid:grid-cols-4"
              : "desktop-grid:grid-cols-2"
          }`}
        >
          {articles.map((article, index) => {
            return <Article data={article} key={index} />;
          })}
        </div>
      </Card>
    </div>
  );
}

interface article {
  attributes: {
    title: string;
    league: string;
    publishedAt: string;
    image: any;
    slug: string;
  };
}

function Article({ data }: { data: article }) {
  const { title, league, publishedAt, image, slug } = data.attributes;
  const thumbnailUrl =
    image?.data?.attributes.formats?.thumbnail.url ||
    image?.data?.attributes.url;
  return (
    <Link
      prefetch={false}
      href={`/news/${slug}`}
      className="flex items-center justify-between mb-4"
    >
      <div className="grid grid-cols-[104px_auto] gap-4 items-center">
        <div className="">
          <Image
            src={thumbnailUrl}
            unoptimized
            width={104}
            height={104}
            className="w-[104px] h-[104px] rounded-xl bg-gray-300 aspect-square object-cover"
            alt={title}
          />
        </div>
        <div className="flex flex-col gap-2">
          <div className="text-title-lg-medium text-dark">{title}</div>
          <div className="flex gap-2 items-center text-secondary text-title-sm-medium">
            <span>{league}</span>
            <span>•</span>
            <span>
              <ReactTimeAgo
                date={new Date(publishedAt)}
                locale="en-US"
                timeStyle="round"
              />
            </span>
          </div>
        </div>
      </div>
    </Link>
  );
}
