"use client";
import Icon from "@/components/primitives/icon";
import Select from "@/components/primitives/select";
import {
  ALL_ODD_OPTIONS,
  ALL_SPORT_OPTIONS,
  ODD_OPTIONS,
} from "@/data/sports/options";
import {
  ApiResponse,
  GameAlignment,
  GameAlignmentType,
  GameOdds,
  HottestGame,
  OddVariant,
  Sport,
  buildOdd,
  toLineType,
} from "common";
import React, {useRef} from "react";
import { useWebSocketMessage } from "@/utils/hooks/websocket-message";
import { calculatePercentage, formatOdd, formatServerDate } from "@/utils";
import { LoadingCircle } from "../primitives/loading";
import NotFound from "../primitives/not-found";
import useFetch from "@/utils/fetch";
import { createGameDetailSlug } from "@/utils/url";
import Link from "next/link";
import { buildHotGamesRequest } from "@/utils/game";
import { useOddsFormat } from "@/utils/providers/odds-format";
import Card from "../primitives/card";
import {useDataLazyload} from "@/utils/hooks/data-lazyload";

interface HotdataProps {
  sport?: Sport;
}

export default function Hotdata(props: HotdataProps) {
  const { oddsFormat } = useOddsFormat();

  const blockRef : React.RefObject<HTMLDivElement> = useRef(null);

  const [selectedSport, setSelectedSport] = React.useState<Sport | "all">(
    props.sport || "all"
  );
  const [selectedOdds, setSelectedOdds] =
    React.useState<OddVariant>("moneyline");
  const wsdata = useWebSocketMessage("game-picks");
  console.log("game picks from ws:", wsdata);

  // Upload / Update data when the block is within viewport

  const request = buildHotGamesRequest(selectedSport, selectedOdds, 5);
  // const { data, isLoading, isError, error } = useFetch<ApiResponse<
  //   HottestGame[]
  // > | null>(null, request, []);

  const { data, isLoading, isVisible, error } = useDataLazyload({
    ref: blockRef,
    url: request,
    dependencies: [selectedSport, selectedOdds]
  });

  function getTotalPicks(pick: HottestGame): number {
    switch (selectedOdds) {
      case "spread":
        return pick.totalSpreadPicks;
      case "moneyline":
        return pick.totalMoneylinePicks;
      case "total":
      case "over":
      case "under":
        return pick.totalOverUnderPicks;
      default:
        return 0;
    }
  }
  function getBarWidth(alignment: GameAlignment, pick: HottestGame): number {
    switch (selectedOdds) {
      case "spread":
        return calculatePercentage(
          alignment === "away" ? pick.awaySpreadPicks : pick.homeSpreadPicks,
          pick.totalSpreadPicks
        );
      case "moneyline":
        return calculatePercentage(
          alignment === "away"
            ? pick.awayMoneylinePicks
            : pick.homeMoneylinePicks,
          pick.totalMoneylinePicks
        );
      case "total":
        return calculatePercentage(
          alignment === "away" ? pick.overPicks : pick.underPicks,
          pick.totalOverUnderPicks
        );
      default:
        return 50;
    }
  }

  return (
    <Card>
      <div ref={blockRef} className="flex justify-between mb-2 gap-y-1 flex-col items-start min-[360px]:items-center min-[360px]:flex-row">
        <div className="flex items-center gap-0.5">
          <Icon name="flame" gradient="flame" size="md" />

          <div className="uppercase text-headline-bold text-secondary-300">
            {props.sport && props.sport} Hot Game Picks
          </div>
        </div>
        <div className="flex items-center gap-3 shrink justify-end">
          <div className="flex items-center gap-1">
            <Select
              label="Select Odd"
              variant="card"
              options={ODD_OPTIONS}
              value={selectedOdds}
              onValueChange={(v) => setSelectedOdds(v as OddVariant)}
            />
            {!props.sport && (
              <Select
                align="end"
                variant="card"
                label="Select Sport"
                options={ALL_SPORT_OPTIONS}
                defaultValue={selectedSport}
                onValueChange={(v) => setSelectedSport(v as Sport | "all")}
              />
            )}
          </div>
        </div>
      </div>
      {isLoading ? (
        <div className="flex justify-center items-center h-[220px]">
          <LoadingCircle />
        </div>
      ) : (!isLoading && (!data?.data || !data.data?.length)) || error ? (
        <NotFound label="No hot games" icon="ticket-buy" />
      ) : (
        data && (
          <>
            <div className="grid grid-cols-[1fr_2fr_1fr] py-3 relative z-[1] text-center uppercase text-label-sm-bold text-secondary">
              <div className="px-3 text-left">Away</div>
              <div className="px-3 text-center">Total Picks</div>
              <div className="px-3 text-right">Home</div>
            </div>
            <div className="flex flex-col gap-5 justify-center">
              {data.data?.map((pick, i) => {
                const awayBarWidth = getBarWidth("away", pick);
                const homeBarWidth = getBarWidth("home", pick);
                const zero: boolean = awayBarWidth === 0 && homeBarWidth === 0;
                return (
                  <Link
                    href={createGameDetailSlug(pick.league, pick.id)}
                    key={i}
                  >
                    <div className="grid grid-cols-[1fr_2fr_1fr] text-center text-text-gray-1">
                      <div className="flex gap-4">
                        <div className="relative z-0">
                          <div className="absolute w-[80px] h-[80px] object-fill left-[-40px] top-[-50%] z-[-1] opacity-10 max-w-none pointer-events-none">
                            <img
                              src={pick.awayTeam.logoUrl}
                              role="presentation"
                              className="w-full h-full"
                            />
                          </div>
                          <div
                            className={`relative rounded-full bg-white border-solid border p-[7px] w-12 flex justify-center`}
                            style={{
                              borderColor:
                                `#${pick.awayTeam.primaryColor}` + "80",
                            }}
                          >
                            <img
                              src={pick.awayTeam.logoUrl}
                              alt={pick.awayTeam.name}
                              className="w-8 h-8"
                            />
                            <div className="absolute -bottom-1 rounded-md bg-white w-[38px] h-4 flex flex-row gap-0.5 items-center justify-center shadow-[0px_5px_14px_0px_rgba(33,39,53,0.07),0px_1px_1px_0px_rgba(33,39,53,0.05),0px_0px_1px_0px_rgba(33,39,53,0.2)]">
                              <div
                                className="w-2 h-2 rounded-full"
                                style={{
                                  backgroundColor: `#${pick.awayTeam.primaryColor}`,
                                }}
                              ></div>
                              <span className="text-label-sm-medium">
                                {awayBarWidth}%
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col items-center justify-center">
                          <div className="text-title uppercase">
                            {pick.awayTeam.abbreviation}
                          </div>
                          <div className="text-label">
                            {
                              formatOdd(
                                oddsFormat,
                                buildOdd(
                                  toLineType(selectedOdds),
                                  GameAlignmentType.Home,
                                  pick.odds
                                )
                              )?.value
                            }
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-col items-center justify-center">
                        <div className="text-sm">{getTotalPicks(pick)}</div>
                      </div>

                      <div className="flex gap-4 justify-end">
                        <div className="flex flex-col items-center justify-center gap-[2px]">
                          <div className="text-title uppercase">
                            {pick.homeTeam.abbreviation}
                          </div>
                          <div className="text-label">
                            {
                              formatOdd(
                                oddsFormat,
                                buildOdd(
                                  toLineType(selectedOdds),
                                  GameAlignmentType.Home,
                                  pick.odds
                                )
                              )?.value
                            }
                          </div>
                        </div>
                        <div className="relative z-0">
                          <div className="absolute w-[80px] h-[80px] object-fill right-[-40px] top-[-50%] z-[-1] opacity-10 max-w-none pointer-events-none">
                            <img
                              src={pick.homeTeam.logoUrl}
                              role="presentation"
                              className="w-full h-full"
                            />
                          </div>
                          <div
                            className={`relative rounded-full bg-white border-solid border p-[7px] w-12`}
                            // style={{ borderColor: pick.awayTeam.Color + "d80" }}

                            style={{
                              borderColor:
                                `#${pick.awayTeam.primaryColor}` + "80",
                            }}
                          >
                            <img
                              src={pick.homeTeam.logoUrl}
                              alt={pick.homeTeam.name}
                              className="w-8 h-8"
                            />
                            <div className="absolute -bottom-1 rounded-md bg-white w-[38px] h-4 flex flex-row gap-0.5 items-center justify-center shadow-[0px_5px_14px_0px_rgba(33,39,53,0.07),0px_1px_1px_0px_rgba(33,39,53,0.05),0px_0px_1px_0px_rgba(33,39,53,0.2)]">
                              <div
                                className="w-2 h-2 rounded-full"
                                style={{
                                  backgroundColor: `#${pick.homeTeam.primaryColor}`,
                                }}
                              ></div>
                              <span className="text-label-sm-medium">
                                {homeBarWidth}%
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex justify-center mt-2 gap-1">
                      <div
                        className="rounded-full h-[6px]"
                        style={{
                          backgroundColor:
                            awayBarWidth === 0
                              ? "var(--secondary-600)"
                              : `#${pick.awayTeam.primaryColor}`,
                          width: zero
                            ? `50%`
                            : homeBarWidth === 100
                            ? "5%"
                            : `${awayBarWidth}%`,
                        }}
                      ></div>
                      <div
                        className="rounded-full h-[6px]"
                        style={{
                          backgroundColor:
                            homeBarWidth === 0
                              ? "var(--secondary-700)"
                              : `#${pick.homeTeam.primaryColor}`,
                          width: zero
                            ? `50%`
                            : awayBarWidth === 100
                            ? "5%"
                            : `${homeBarWidth}%`,
                        }}
                      ></div>
                    </div>
                  </Link>
                );
              })}
            </div>
          </>
        )
      )}
    </Card>
  );
}
