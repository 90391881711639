'use client';

import React, {useRef} from "react";

import Button from "../primitives/button";
import Card from "../primitives/card";

import {useDataLazyload} from "@/utils/hooks/data-lazyload";
import Image from "next/image";

export default function BettingGuide() {
    const blockRef: React.RefObject<HTMLDivElement> = useRef(null);

    // Check is block within viewport

    const { isVisible } = useDataLazyload({
      ref: blockRef,
    });

    return (
      <>
        <Card custom dark className="py-14 px-6">
          {/*<div*/}
          {/*  className="absolute inset-0 z-10 bg-cover bg-no-repeat bg-center bg-opacity-10"*/}
          {/*  style={{ backgroundImage: "url(/images/hoop.png)" }}*/}
          {/*></div>*/}

          {
              isVisible && <Image
                  src='/images/hoop.png'
                  width={416}
                  height={458}
                  className="w-[100%] h-[100%] object-cover absolute inset-0 z-10"
                  alt="Learn how to bet on Sports"
              />
          }

          <div ref={blockRef} className="relative z-30 text-white text-center flex flex-col items-center gap-6">
            <div className="w-[60px] h-[2px] bg-[#AEE449] mx-auto"></div>
            <div className="uppercase text-display wrap-balance">
              Learn how to <br></br>bet on Sports
            </div>
            <p className="text-base leading-7">
              Want to learn more about sports betting? Our Betting & Education
              provides quick-start guides on the basics of sports bettings, odds,
              as well as some strategies to get you betting on a new level.
            </p>
            <Button href="/betting-guide/sportsbetting-101">
            View Betting Guide
            </Button>

          </div>
        </Card>
      </>
    );
  }
