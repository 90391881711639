// TODO: REMOVE - DEPRECATED

"use client";
import { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { BettorSettings } from "common";
import { useAuth, useUser } from "@clerk/nextjs";

interface SettingsContextType {
  settings: BettorSettings | null;
  loading: boolean;
  error: string | null;
}
const SettingsContext = createContext<SettingsContextType | undefined>(
  undefined
);

export const useSettings = () => {
  const context = useContext(SettingsContext);
  if (context === undefined) {
    throw new Error("useSettings must be used within a SettingsProvider");
  }
  return context;
};
export const SettingsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [settings, setSettings] = useState<BettorSettings | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const { user, isLoaded } = useUser();

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        setLoading(true);
        const response = await axios.get<BettorSettings>(
          `${process.env.NEXT_PUBLIC_API_URL}/api/users/${user?.username?.toLowerCase()}/settings`
        );
        console.log("user settings response:", response);
        setSettings(response.data);
      } catch (err) {
        if (axios.isAxiosError(err)) {
          setError(err.message);
        } else {
          setError("An unexpected error occurred");
        }
      } finally {
        setLoading(false);
      }
    };
  
    if (user && isLoaded && user.username) {
      fetchSettings();
    } else {
      setLoading(false);
    }
  }, [user, isLoaded]);

  return (
    <SettingsContext.Provider value={{ settings, loading, error }}>
      {children}
    </SettingsContext.Provider>
  );
};
