"use client";
import Icon from "@/components/primitives/icon";
import Button from "@/components/primitives/button";
import Card from "@/components/primitives/card";
import { ApiResponse, HottestGame, Sport } from "common";
import PickGroup from "@/components/pick/group";
import OddsGroup from "../odds/group";
import Badge from "@/components/primitives/badge";
import React from "react";
import { useWebSocketMessage } from "@/utils/hooks/websocket-message";
import { createGameDetailSlug } from "@/utils/url";
import Timestamp from "../timestamp";
import GameCardSeriesChip from "./series-chip";
import Image from "next/image";

function GameCardHottest({sport, res}: {sport?: Sport, res: ApiResponse<HottestGame[]>}) {
  const wsHottestGame = useWebSocketMessage("hottest-game");
  console.log("hottest game from ws:", wsHottestGame);

  if (res && res.data) {
    const data = res.data[0];
    return (
      <Button
        unstyled
        href={createGameDetailSlug(data.league, data.id)}
        data-prevent-nprogress={true}
        prefetch={false}
        className="flex relative z-[1]"
      >
        <div className="absolute w-full left-0 right-0 -top-3 flex justify-center z-[4]">
          <div className="flex items-center justify-center">
            {data.series && <GameCardSeriesChip info={data.series} neutral />}
          </div>
        </div>
        <div className={`flex flex-col overflow-hidden bg-secondary-300 rounded-md card ${data.series ? "pt-5" : "pt-3"} pb-2 desktop-grid:pb-5 px-4 w-full h-full relative z-[3]`}>
          <div className="flex justify-between items-center flex-wrap desktop-grid:flex-nowrap">
            <div className="whitespace-nowrap gap-1 uppercase text-dark items-center flex text-title-bold text-white pr-4 pb-3">
              <Icon name="flame" gradient="flame" size="sm"></Icon> Hottest Game
            </div>
            <div className="flex gap-2 text-label-sm-medium uppercase text-white pb-3">
              <Badge
                color="white"
                value={data.totalPicks}
                showZero={true}
                icon={{ name: "flame", gradient: "flame" }}
                label={`${data.totalPicks === 1 ? "Pick" : "Picks"}`}
              />
              <PickGroup
                dark
                avatars={data.avatars || []}
                totalBettors={data.totalBettors}
                totalPicksForSale={data.totalPicksForSale}
              ></PickGroup>
            </div>
          </div>
          <div className="flex flex-row desktop-grid:flex-col gap-2 flex-wrap justify-center">
            <div className="text-headline-bold text-white uppercase flex items-center justify-center desktop-grid:justify-start gap-1">
              <div>
              <span className="hidden sm:block">{data.awayTeam.location}</span>

              <span className="block sm:hidden">
                    {data.awayTeam.abbreviation}
                  </span>
              </div>
              <span className="text-title-sm">@</span>
              <div>

              <span className="hidden sm:block">{data.homeTeam.location}</span>
              <span className="block sm:hidden">
                    {data.homeTeam.abbreviation}
                  </span>
              </div>
            </div>
            <div className="flex justify-center items-center desktop-grid:justify-start">
              <Timestamp
                dateTime={data.dateTime}
                platform={data.platform}
                dark
                className="text-label-lg-bold"
                sport={data.league}
              />
            </div>
          </div>
          <div className="flex flex-col order-2 desktop-grid:flex-row gap-4 justify-center desktop-grid:justify-between items-center mt-3 desktop-grid:mt-0 desktop-grid:items-end">
            <div className="hidden desktop-grid:flex order-2 desktop-grid:order-1">
              <span className="text-label-lg text-secondary-700">
                {data.temperature ? (
                  <span>{data.temperature}°</span>
                ) : undefined}{" "}
                <span>{data.stadium?.name}</span>
              </span>
            </div>
            <div className="hidden order-1 desktop-grid:flex desktop-grid:order-2 gap-2 items-center">
              <Button
                href={createGameDetailSlug(data.league, data.id)}
                color="secondary"
                size="xs"
                forceButton
              >
                View Game
              </Button>
            </div>
          </div>
          {/* FOOTER */}
          <div className="static mt-1 desktop-grid:mt-0 desktop-grid:absolute w-full max-w-[21rem] m-auto inset-0 flex justify-center items-center">
            <div
              className="p-[2px] rounded-md"
              style={{
                background: `linear-gradient(to right, #${data.awayTeam.primaryColor}, #${data.homeTeam.primaryColor}), #FFFFFF`,
              }}
            >
              <OddsGroup game={data} lockSide="right" color="dark" record />
            </div>
          </div>
          <div className="pointer-events-none z-[-1] absolute top-[10%] w-[50%] h-full desktop-grid:w-[30rem] desktop-grid:h-[30rem] desktop-grid:-top-full left-0 desktop-grid:left-[10%] opacity-20 flex justify-center items-center">
            <div
              className="w-full h-full absolute"
              style={{
                background: `linear-gradient(270deg, rgba(35, 37, 40, 0) 30%, rgba(35, 37, 40, 1) 100%)`,
              }}
            ></div>
            <Image
                src={data.awayTeam.logoUrl}
                width={480}
                height={480}
                className="w-full h-full object-contain"
                alt={`${data.awayTeam.name} Logo Splash`}
            />

            {/*<img*/}
            {/*  src={data.awayTeam.logoUrl}*/}
            {/*  className="w-full h-full object-contain"*/}
            {/*  alt={`${data.awayTeam.name} Logo Splash`}*/}
            {/*/>*/}
          </div>
          <div className="pointer-events-none z-[-1] absolute top-[10%] w-[50%] h-full desktop-grid:w-[30rem] desktop-grid:h-[30rem] desktop-grid:-top-full right-0 desktop-grid:right-[10%] opacity-20 flex justify-center items-center">
            <div
              className="w-full h-full absolute"
              style={{
                background: `linear-gradient(90deg, rgba(35, 37, 40, 0) 30%, rgba(35, 37, 40, 1) 100%)`,
              }}
            ></div>

            <Image
                src={data.homeTeam.logoUrl}
                width={480}
                height={480}
                className="w-full h-full object-contain"
                alt={`${data.homeTeam.name} Logo Splash`}
            />

            {/*<img*/}
            {/*  src={data.homeTeam.logoUrl}*/}
            {/*  className="w-full h-full object-contain"*/}
            {/*  alt={`${data.homeTeam.name} Logo Splash`}*/}
            {/*/>*/}
          </div>
          <Card.Trim
            fromColor={data.awayTeam.primaryColor}
            toColor={data.homeTeam.primaryColor}
          />
        </div>
      </Button>
    );
  }
  // if (isLoading) {
  //   return (
  //     <div className="flex justify-center items-center h-[220px]">
  //       <LoadingCircle />
  //     </div>
  //   );
  // }
  // if (!isLoading && !data) {
  //   return undefined;
  // }
}

export default GameCardHottest;
