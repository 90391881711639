"use client";
import ReactTimeAgo from "react-time-ago";
import Link from "next/link";
import Image from "next/image";
import RelativeTime from "../relative-time";

interface article {
  attributes: {
    title: string;
    league: string;
    image: any;
    publishedAt: string;
    slug: string;
  };
}

export default function FeaturedNews({ articles }: { articles: article[] }) {
  return (
    <div className="grid grid-cols-1 desktop-news:grid-cols-2 desktop-grid:grid-cols-2 gap-4">
      {articles?.map((article, index) => {
        const { title, league, publishedAt, image, slug } = article.attributes;
        const thumbnailUrl =
          image?.data?.attributes.formats?.medium.url ||
          image?.data?.attributes.url;
        return (
          <Link
            href={`/news/${slug}`}
            prefetch={false}
            key={index}
            className="flex flex-col gap-2 bg-white rounded-xl shadow-card"
          >
            <div className="image overflow-hidden rounded-t-xl">
              <Image
                src={thumbnailUrl}
                unoptimized
                width={364}
                height={205}
                alt={title}
                className="w-full aspect-video h-auto object-cover"
              />
            </div>
            <div className="px-6 py-5 flex flex-col gap-2">
              <div className="text-lg font-bold">{title}</div>
              {/* Turn into component */}
              <div className="flex gap-2 items-center text-title-sm-medium text-secondary">
                <div>{league}</div>
                <div>•</div>
                <div>
                  <RelativeTime  date={publishedAt}/>
                </div>
              </div>
            </div>
          </Link>
        );
      })}
    </div>
  );
}
