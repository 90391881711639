import React from 'react';

export default function GameCardSeriesChip({info, neutral}: {info: string, neutral?: boolean}) {
    const color: string = neutral ? "text-secondary-200 bg-surface-700" : "text-secondary-400 bg-secondary-700";
  return (
    <div className={`shadow-elevation-200 flex justify-center items-center px-2 py-1 rounded-full text-label-medium uppercase ${color}`}>
        {info}
    </div>
  )
}
